import React from 'react';
import axios from 'axios';

import RecipeList from './RecipeList';
import Categories from './Categories';
import Login from './Login';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';

import { Link} from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Cookbook extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        selectedBook: null,
        selectedCategory: null,
        toggled: !this.isNarrow(),
        narrow: this.isNarrow(),
        showLogin: null,
        loggedIn: null,
    }
  }

  isNarrow() {
    return  window.innerWidth < 768
  }

  handleResize() {
    var narrow = this.isNarrow()

    if (this.state.narrow !== narrow) {
        this.setState({narrow: narrow, toggled: !narrow})
    }
  }

  updateBookFromShortName(short_name) {
    axios.get(`/api/books/`)
    .then(res => {
       this.setState({selectedBook: res.data.find(element => element.short_name === short_name)})
     })
  }

  componentDidMount() {
    this.updateBookFromShortName(this.props.selectedBook)
    window.addEventListener("resize", this.handleResize.bind(this));

    axios.get(`/api/is-logged-in`)
      .then(res => {
          this.setState({loggedIn: res.data.authenticated})
      })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize.bind(this));
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.updateBookFromShortName(this.props.selectedBook)
    }
  }

  toggleSidebar() {
    this.setState({toggled: !this.state.toggled})
  }

  logout() {
    axios.get(`/api/logout/`)
      .then(res => {
        if (res.status === 200) {
          this.setState({loggedIn: false})
        }
      })
  }

  render() {
    return (
        <div>
          <Navbar className="ml-auto">
            <Button variant="light" type="submit" onClick={() => this.toggleSidebar()}><FontAwesomeIcon icon="bars" /></Button>
            &nbsp;&nbsp;<Navbar.Brand as={Link} to="/book/">Kochbuch</Navbar.Brand>
            { this.state.loggedIn == null ? <div></div> : !this.state.loggedIn ?
              <Button className="ml-auto" variant="light" type="submit" onClick={() => this.setState({showLogin: true})}>Login</Button> :
              <Button className="ml-auto" variant="light" type="submit" onClick={() => { this.logout() }}>Logout</Button>
            }
          </Navbar>

          <Login show={this.state.showLogin} onLoggedIn={() => this.setState({loggedIn: true})} onClose={() => this.setState({showLogin: false})} />

          <div className={(this.state.toggled ? "d-flex toggled" : "d-flex")} id="wrapper">
            <div className="bg-light border-right" id="sidebar-wrapper">
                <div className="sidebar-heading">Kategorien</div>
                <div className="list-group list-group-flush">
                    <Categories selectedBook={this.state.selectedBook}
                                selectedCategory={this.state.selectedCategory}
                                onSelected={(category) => this.setState({selectedCategory: category, toggled: this.state.narrow ? !this.state.toggled : true})} />
                </div>
            </div>
            <div id="page-content-wrapper" className={(this.state.narrow ? "" : " wide" )}>
                <div className="container-fluid"></div>
            <RecipeList selectedBook={this.state.selectedBook} selectedCategory={this.state.selectedCategory} />
          </div>
        </div>
      </div>
    )
  }
}
