import React from 'react';
import axios from 'axios';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

import { Link } from 'react-router-dom'

export default class CookbookSelection extends React.Component {
  state = {
    books: [],
  }

  componentDidMount() {
    axios.get(`/api/books/`)
    .then(res => {
      const books = res.data;
      this.setState({ books: books });
    })
  }

  render() {
    return (
      <div>
      <h1 style={{display: 'flex', justifyContent: 'center'}}>Kochbuch</h1>
      <p style={{display: 'flex', justifyContent: 'center'}}>Wähle ein Buch aus, um die Rezepte zu sehen.</p>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <ButtonGroup vertical size="lg">
          { this.state.books.map(book =><Button as={Link} to={"/book/"+book.short_name} >{book.name}</Button>)}
        </ButtonGroup>
        </div>
        </div>
    )
  }
}
