import React from 'react';

import Cookbook from './Cookbook';
import CookbookSelection from './CookbookSelection';

import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { useCookies } from "react-cookie";


function MainPageForward(props) {
  const content = props['book'] ? <Navigate to={"/book/"+props.book} /> : <Navigate to={"/book/"} />
  return (<div>{content}</div>)
}

function MainPageSelect() {
  const [, , removeCookie] = useCookies(["user"]);
  removeCookie("cookbook")
  return (<CookbookSelection />)
}

function CookbookRoute() {
    let params = useParams();

    const [, setCookie] = useCookies(["user"]);
    setCookie("cookbook", params.book, { path: "/", maxAge: 31536000 });

    return(
      <Cookbook selectedBook={params.book} selectedCategory={null} onSelectedBook={(book) => {}}/>
  );
}

export default function App() {
  const [cookies] = useCookies(["user"]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<MainPageForward book={cookies['cookbook']} />} />
        <Route path="/book" element={<MainPageSelect />} />
        <Route path="/book/:book" element={<CookbookRoute />} />
      </Routes>
    </div>
  )
}
