import React from 'react';
import axios from 'axios';

import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form'

import Recipe from './Recipe'

export default class RecipeList extends React.Component {
  state = {
    recipes: [],
    selectedRecipe: null,
    showRecipe: null,
    currentFilter: ""
  }

  componentDidMount() {
    this.updateFilter(this.state.currentFilter)
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.updateFilter(this.state.currentFilter)
    }
  }

  updateFilter(search) {
    if (this.state.currentFilter !== search) {
      this.setState({ currentFilter: search })
    }

    if (this.props.selectedBook !== null) {
      var categoryId = this.props.selectedCategory ? this.props.selectedCategory.id : ""

      axios.get(`/api/books/`+this.props.selectedBook.id+`/recipes/?search=`+search+`&category=`+categoryId)
      .then(res => {
        var recipes = res.data;
        console.log(recipes)
        this.setState({ 'recipes': recipes });
      })
    }
  }

  updateRecipe(id) {
    axios.get(`/api/books/`+this.props.selectedBook.id+`/recipes/`+id+`/`)
    .then(res => {
      const recipe = res.data;
      this.setState({selectedRecipe: recipe, showRecipe: true})
    })
  }

  render() {
    const searchFieldRef = React.createRef(); /* needed to reference search field */

    return (
      <div>
        <Recipe show={this.state.showRecipe} recipe={this.state.selectedRecipe} onClose={() => this.setState({showRecipe: null})} />
        {/* prevent form submitting, as search is already performed while typing; but remove focus anyway */}
        <Form onSubmit={(event) => {searchFieldRef.current.blur(); event.preventDefault(); event.stopPropagation(); }}>
          <Form.Control ref={searchFieldRef} placeholder="Suche" onSubmit={(event) => console.log("bla")} onChange={(e) => {this.updateFilter(e.target.value)}} />
        </Form>
        <ListGroup>
          { this.state.recipes.map(recipe =><ListGroup.Item action onClick={() => this.updateRecipe(recipe.id)}>{recipe.name}</ListGroup.Item>)}
        </ListGroup>
      </div>
    )
  }
}
