import React from 'react';
import axios from 'axios';

import Modal from 'react-bootstrap/Modal'
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";

export default class Login extends React.Component {
  state = {
      errorActive: false
  }

  handleSubmit(event) {
    event.preventDefault();
    axios.post("/api/login", {'username': event.target.username.value, 'password': event.target.password.value})
        .then(res => {
            if (res.status === 200) {
                this.setState({errorActive: false})
                this.props.onLoggedIn()
                this.props.onClose()
            } else {
                this.setState({errorActive: true})
            }
        })
        .catch(error => {
            this.setState({errorActive: true})
        })
  }

  render() {
    return (
        <Modal
          show={this.props.show}
          onHide={() => { this.props.onClose(); this.setState({errorActive: false}) }}
          backdrop="static"
          keyboard={true}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { this.state.errorActive ?
          <div class="alert alert-danger alert-dismissible fade show">
            Benutzername oder Passwort ungültig.
          </div>
          : <div></div>}
          <form onSubmit={(e) => this.handleSubmit(e)}>
            <FormGroup controlId="username" bsSize="large">
              <FormLabel>Name</FormLabel>
              <FormControl autoFocus type="text" required />
            </FormGroup>
            <FormGroup controlId="password" bsSize="large">
              <FormLabel>Passwort</FormLabel>
              <FormControl type="password" required />
            </FormGroup>
            <Button block bsSize="large" type="submit">Login</Button>
          </form>
        </Modal.Body>
      </Modal>
    )
  }
}
