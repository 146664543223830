import React from 'react';
import './Recipe.css';

import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal'

export default class Recipe extends React.Component {
  state = {

  }

  render() {
    return (
        <Modal
        show={this.props.show}
        onHide={this.props.onClose}
        backdrop="static"
        keyboard={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.recipe ? this.props.recipe.name : ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {this.props.recipe && this.props.recipe.image ? <img  src={'/static/recipe-images/'+this.props.recipe.image} width='100%' style={{'margin-bottom': '1em'}} /> : ""}
        {this.props.recipe && this.props.recipe.description ? <p>{this.props.recipe.description}</p> : ""}
        {this.props.recipe && this.props.recipe.portions ? <p><b>Menge: </b>{this.props.recipe.portions} Portion{this.props.recipe.portions > 1 ? "en" : "" }</p> : ""}
        <b>Zutaten</b>
        <ListGroup variant="flush">
        { 
            this.props.recipe !== null && this.props.recipe.ingredients !== undefined &&
              this.props.recipe.ingredients.map(ingredient =><ListGroup.Item>{ingredient}</ListGroup.Item>)
        }
        </ListGroup>
        <b>Zubereitung</b>
        <ListGroup variant="flush">
          { 
            this.props.recipe !== null && this.props.recipe.instructions !== null &&
              this.props.recipe.instructions.map(instruction =><ListGroup.Item>{instruction}</ListGroup.Item>)
          }
        </ListGroup>
        {this.props.recipe && this.props.recipe.tools.length > 0 ? <b>Utensilien</b> : ""}
        <ListGroup variant="flush">
        {this.props.recipe && this.props.recipe.tools.length > 0 &&
          this.props.recipe.tools.map(tool =><ListGroup.Item>{tool}</ListGroup.Item>)
        }
        </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.props.onClose}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
