import React from 'react';
import axios from 'axios';

import ListGroup from 'react-bootstrap/ListGroup';

export default class RecipeList extends React.Component {
  state = {
    categories: []
  }

  updateCategories() {
    if (this.props.selectedBook == null) { return }

    axios.get(`/api/books/`+this.props.selectedBook.id+`/categories/`)
    .then(res => {
      const categories = res.data;
      this.setState({ categories: categories });
    })
  }

  componentDidMount() {
    this.updateCategories()
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.updateCategories()
    }
  }

  render() {
    return (
        <ListGroup variant="flush"> 
          <ListGroup.Item active={this.props.selectedCategory === null} action onClick={() => {this.props.onSelected(null)}}>Alle</ListGroup.Item>
          { this.state.categories.map(category =><ListGroup.Item active={this.props.selectedCategory !== null && category.id === this.props.selectedCategory.id} action onClick={() => {this.props.onSelected(category)}}>{category.name}</ListGroup.Item>)}
        </ListGroup>
    )
  }
}
